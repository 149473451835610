<template>
    
    <div class="row col-12 col-md-12 offset-md-0 tufont tutablepadding text-start tusmallerfontsize tublue tuwhiteboxlist" style="outline:0.0em dashed red; height: auto; float: left; margin-top: 1.5vw;">
        <span class="col-1 offset-2 col-md-4 offset-md-0 tufont tublue;" style="font-weight: 600; outline:0.00em dashed green;" >{{ this.ListItemString1 }}</span>
        <span class="col-8 col-md-8" style="outline:0.00em dashed blue;" v-html="this.ListItemString2"></span>
    </div>
   
</template>

<script>
export default {
    name: 'MoreInfoItem',
    props:  {
        ListItemString1: String,
        ListItemString2: String,
    },
} 
</script> 
   
           
           
